(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("fs"), require("websocket"), require("any-promise"), require("bindings"));
	else if(typeof define === 'function' && define.amd)
		define("truffle-hdwallet-provider", ["fs", "websocket", "any-promise", "bindings"], factory);
	else if(typeof exports === 'object')
		exports["truffle-hdwallet-provider"] = factory(require("fs"), require("websocket"), require("any-promise"), require("bindings"));
	else
		root["truffle-hdwallet-provider"] = factory(root["fs"], root["websocket"], root["any-promise"], root["bindings"]);
})(global, function(__WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__60__, __WEBPACK_EXTERNAL_MODULE__132__, __WEBPACK_EXTERNAL_MODULE__203__) {
return 